import { useEffect, useState, useDeferredValue } from 'react';
import { FormValueProps, FormErrorsProps } from '../interfaces/interfaces';
import { CountryData } from 'react-phone-input-2';
import validation from '../utils/validation';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const useForm = (initValues: FormValueProps, error: FormErrorsProps) => {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setLoadingImg] = useState(false);

  //inputs state
  const [values, setValues] = useState<FormValueProps>({
    ...initValues,
  });

  const deferredValue = useDeferredValue(values);

  //inputs errors
  const [errors, setErrors] = useState<FormErrorsProps>({
    ...error,
  });

  //validation reset
  const [isValidating, setIsValidating] = useState(false);

  //to disable button
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      axios.post('https://im-brief.up.railway.app/api', {
        ...values,
        briefType: 'BRANDING BRIEF',
      });

      setIsLoading(false);
      MySwal.fire({
        toast: true,
        icon: 'success',
        title: 'El brief ha sido enviando exitosamente. Lo estaremos contactando proximamente',
        position: 'top-end',
        showConfirmButton: false,
        timer: 8000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    } catch (error) {
      let errorMessage;
      errorMessage =
        'Opps, ha ocurrido un error, por favor, verifique su conexion a internet e intente nuevamente';

      MySwal.fire({
        toast: true,
        icon: 'error',
        title: errorMessage,
        position: 'top-end',
        showConfirmButton: false,
        timer: 12000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      setIsLoading(false);
    } finally {
      setIsValidating(false);
      setValues({ ...initValues });
    }
  };

  const inputFileHandleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files, name } = e.target;
    setLoadingImg(true);

    if (!isValidating) {
      setIsValidating(true);
    }

    try {
      const fileData = files && files?.length > 0 ? files[0] : null;
      const imgbbData = await axios.post(
        'https://imgbb-api.up.railway.app/api',
        {
          file: fileData,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setValues((prevFormData) => {
        return {
          ...prevFormData,
          [name]: imgbbData.data.url,
        };
      });
      setLoadingImg(false);
    } catch (error) {
      setLoadingImg(false);
    }
  };

  const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }
    setValues((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : type === 'file' ? 'hola' : value,
      };
    });
  };

  const compountInputHandleChange = (name: string, nest: {}) => {
    if (!isValidating) {
      setIsValidating(true);
    }
    setValues((prevFormData) => {
      return { ...prevFormData, [name]: { ...nest } };
    });
  };

  const selectHandleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: value };
    });
  };

  const phoneNumberHandleChange = (
    value: string,
    data: {} | CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
    nest?: {}
  ) => {
    const { name } = event.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    if (!!!nest) {
      setValues((prevFormData) => {
        return { ...prevFormData, [name]: formattedValue };
      });
    } else {
      setValues((prevFormData) => {
        return { ...prevFormData, [name]: { ...nest } };
      });
    }
  };

  const textAreaHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: value };
    });
  };

  useEffect(() => {
    setErrors(isValidating ? validation(values) : { ...error });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredValue]);

  useEffect(() => {
    setDisableSubmit(isValidating ? Object.values(errors).some((error) => error !== '') : true);
  }, [errors, isValidating]);

  return {
    values,
    errors,
    disableSubmit,
    isValidating,
    setValues,
    handleSubmit,
    phoneNumberHandleChange,
    inputHandleChange,
    selectHandleChange,
    compountInputHandleChange,
    textAreaHandleChange,
    inputFileHandleChange,
    isLoading,
    isLoadingImg,
  };
};

export default useForm;
