import { useId } from 'react';
import {
  FormDataList,
  FormInput,
  FormPhoneInput,
  FormTextArea,
  RegularButton,
  FormRadio,
  FormSelect,
} from 'infrastructure/components';
import { consumerIdealAge, countries, formErrors, formValues, staffAmount } from './utils';
import { useForm } from './hooks';

import { BeatLoader } from 'react-spinners';
import * as S from './BriefForm.styled';
import moment from 'moment';

const BriefForm = () => {
  const inputId = useId();

  const {
    values,
    errors,
    disableSubmit,
    isValidating,
    isLoading,
    isLoadingImg,
    handleSubmit,
    phoneNumberHandleChange,
    inputHandleChange,
    textAreaHandleChange,
    selectHandleChange,
    inputFileHandleChange,
  } = useForm(formValues, formErrors);

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.FieldSet>
        <S.FieldLegend>Informacion Basica De La Compañia</S.FieldLegend>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-companyNameInput`}
            name='companyName'
            type='text'
            placeholder='Compañia'
            onChange={inputHandleChange}
            value={values.companyName}
            required
            formInputProps={{
              label: 'Nombre de la compañía *',
              errorMessage: errors.companyName,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-dateFoundedInput`}
            name='companyStartDate'
            max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
            type='date'
            onChange={inputHandleChange}
            value={values.companyStartDate}
            formInputProps={{
              label: 'Fecha en que fue fundada',
              errorMessage: errors.companyStartDate,
              withCheck: false,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-emailInput`}
            name='companyEmail'
            type='email'
            placeholder='email@email.com'
            onChange={inputHandleChange}
            value={values.companyEmail}
            required
            formInputProps={{
              label: 'Correo *',
              errorMessage: errors.companyEmail,
              withCheck: true,
              isValidating,
            }}
          />
          <FormPhoneInput
            id={`${inputId}-phoneInput`}
            name='phoneNumber'
            placeholder='1 (234) 567-8910'
            formInputProps={{
              label: 'Número de telefono *',
              errorMessage: errors.phoneNumber,
              withCheck: true,
              onChange: phoneNumberHandleChange,
              value: values.phoneNumber || '',
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>
      <S.FieldSet>
        <S.FieldLegend>Informacion De Ubicación</S.FieldLegend>
        <FormInput
          id={`${inputId}-address1Input`}
          name='address1'
          type='text'
          placeholder='Indica sector/calle/edificio'
          onChange={inputHandleChange}
          value={values.address1}
          formInputProps={{
            label: 'Dirección 1 *',
            errorMessage: errors.address1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-address2Input`}
          name='address2'
          type='text'
          placeholder='Indica sector/calle/edificio'
          onChange={inputHandleChange}
          value={values.address2}
          formInputProps={{
            label: 'Dirección 2',
            errorMessage: errors.address2,
            withCheck: true,
            isValidating,
          }}
        />
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-cityInput`}
            name='city'
            type='text'
            placeholder='Barcelona'
            onChange={inputHandleChange}
            value={values.city}
            formInputProps={{
              label: 'Ciudad',
              errorMessage: errors.city,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-stateInput`}
            name='state'
            type='text'
            placeholder='Anzoátegui'
            onChange={inputHandleChange}
            value={values.state}
            formInputProps={{
              label: 'Estado',
              errorMessage: errors.state,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-zipCodeInput`}
            name='zipCode'
            type='text'
            placeholder='0230'
            onChange={inputHandleChange}
            value={values.zipCode}
            formInputProps={{
              label: 'Código De Area',
              errorMessage: errors.zipCode,
              withCheck: false,
              isValidating,
            }}
          />

          <FormDataList
            id={`${inputId}-countryInput`}
            name='country'
            type='text'
            placeholder='Venezuela (VE)'
            onChange={inputHandleChange}
            value={values.country}
            required
            formInputProps={{
              options: countries,
              label: 'Pais *',
              errorMessage: errors.country,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Informacion Detallada De La Empresa</S.FieldLegend>

        <FormSelect
          id={`${inputId}-staffAmountInput`}
          name='staffAmount'
          onChange={selectHandleChange}
          value={values.staffAmount}
          required
          formSelectProps={{
            errorMessage: errors.staffAmount,
            label: 'Cuantas personas trabajan en la empresa *',
            options: staffAmount,
            withCheck: false,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-briefCompanyHistoryInput`}
          name='briefCompanyHistory'
          onChange={textAreaHandleChange}
          value={values.briefCompanyHistory}
          required
          formTextAreaProps={{
            errorMessage: errors.briefCompanyHistory,
            label: 'Breve descripción de la empresa *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-missionInput`}
          name='missionVision'
          onChange={textAreaHandleChange}
          value={values.missionVision}
          required
          formTextAreaProps={{
            errorMessage: errors.missionVision,
            label: 'Misión & visión *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-industrySectorInput`}
          name='industrySector'
          onChange={textAreaHandleChange}
          value={values.industrySector}
          required
          formTextAreaProps={{
            errorMessage: errors.industrySector,
            label: 'En qué tipo de industria en específico trabaja? *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-productOrServiceInput`}
          name='productOrService'
          onChange={textAreaHandleChange}
          value={values.productOrService}
          required
          formTextAreaProps={{
            errorMessage: errors.productOrService,
            label: 'Qué productos y servicios ofrece su compañia actualmente? *',
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Informacion De La Marca</S.FieldLegend>
        <FormTextArea
          id={`${inputId}-fiverWordsDescribeBrandInput`}
          name='fiverWordsDescribeBrand'
          onChange={textAreaHandleChange}
          value={values.fiverWordsDescribeBrand}
          required
          formTextAreaProps={{
            errorMessage: errors.fiverWordsDescribeBrand,
            label: 'Nombrar cinco (5) palabras que definan la Marca *',
            withCheck: true,
            isValidating,
          }}
        />
        <S.Label>Por favor nombre 3 competidores principales:</S.Label>
        <FormInput
          id={`${inputId}-competitors1Input`}
          name='competitors1'
          type='text'
          placeholder='Competidor 1 *'
          onChange={inputHandleChange}
          value={values.competitors1}
          formInputProps={{
            errorMessage: errors.competitors1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-competitors2Input`}
          name='competitors2'
          type='text'
          placeholder='Competidor 2'
          onChange={inputHandleChange}
          value={values.competitors2}
          formInputProps={{
            errorMessage: errors.competitors2,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-competitors1Input`}
          name='competitors3'
          type='text'
          placeholder='Competidor 3'
          onChange={inputHandleChange}
          value={values.competitors3}
          formInputProps={{
            errorMessage: errors.competitors3,
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-diferenceBetwenYourBrandAndCompetitorsInput`}
          name='diferenceBetwenYourBrandAndCompetitors'
          onChange={textAreaHandleChange}
          value={values.diferenceBetwenYourBrandAndCompetitors}
          required
          formTextAreaProps={{
            label: 'En que se diferencia tu marca de la competencia: *',
            errorMessage: errors.diferenceBetwenYourBrandAndCompetitors,
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Informacion del consumidor</S.FieldLegend>
        <S.Label>¿Género del consumidor ideal?: *</S.Label>

        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderM`,
              label: 'Masculino',
              name: 'idealConsumerGender',
              radioValue: 'Masculino',
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderF`,
              label: 'Femenino',
              name: 'idealConsumerGender',
              radioValue: 'Femenino',
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderA`,
              label: 'Ambos',
              name: 'idealConsumerGender',
              radioValue: 'Ambos',
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.idealConsumerGender}</S.Error>
        </S.RadioCont>

        <S.Label>¿De que genero se compone principalmente el consumidor?: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderM`,
              label: 'Masculino',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Masculino',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderF`,
              label: 'Femenino',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Femenino',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderA`,
              label: 'Ambos',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Ambos',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderO`,
              label: 'Otros',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Otros',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.actualPrincipalConsumerGender}</S.Error>
        </S.RadioCont>

        <FormSelect
          id={`${inputId}-idealConsumerAge`}
          name='idealConsumerAge'
          onChange={selectHandleChange}
          value={values.idealConsumerAge}
          required
          formSelectProps={{
            errorMessage: errors.idealConsumerAge,
            label: 'Edad del consumidor ideal: *',
            options: consumerIdealAge,
            withCheck: false,
            isValidating,
          }}
        />

        <S.Label>Ubicación del consumidor ideal: *</S.Label>
        <S.CheckBoxCont>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-idealConsumerLocationNacional`}
              name='idealConsumerLocationisNacional'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisNacional}
              formInputProps={{
                label: 'Nacional',
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaLatina`}
              name='idealConsumerLocationisAmericaLatina'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaLatina}
              formInputProps={{
                label: 'América Latina',
                withCheck: false,
                isValidating,
              }}
            />

            <FormInput
              id={`${inputId}-idealConsumerLocationisNorteAmerica`}
              name='idealConsumerLocationisNorteAmerica'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisNorteAmerica}
              formInputProps={{
                label: 'Norteamérica',
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaYEuropa`}
              name='idealConsumerLocationisAmericaYEuropa'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaYEuropa}
              formInputProps={{
                label: 'América Y Europa',
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaYAsia`}
              name='idealConsumerLocationisAmericaYAsia'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaYAsia}
              formInputProps={{
                label: 'América Y Asia',
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisInternacional`}
              name='idealConsumerLocationisInternacional'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisInternacional}
              formInputProps={{
                label: 'Internacional',
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.Error $isCheckBox>{errors.idealConsumerLocation}</S.Error>
        </S.CheckBoxCont>

        <S.Label>Nivel socieconómico del consumidor ideal: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlA`,
              label: 'Clase alta',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Clase alta',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlM`,
              label: 'Clase media',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Clase media',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlB`,
              label: 'Clase baja',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Clase baja',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />

          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlT`,
              label: 'Todas Las Anteriores',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Todas (baja, media, alta)',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.idealConsumerSocioEconomicLvl}</S.Error>
        </S.RadioCont>
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Informacion detallada de la marca</S.FieldLegend>
        <S.Label>¿Tiene slogan?: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-haveSloganSi`,
              label: 'Si',
              name: 'haveSlogan',
              radioValue: 'Si',
              value: values.haveSlogan,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-haveSloganNo`,
              label: 'No',
              name: 'haveSlogan',
              radioValue: 'No',
              value: values.haveSlogan,
              handleChange: inputHandleChange,
            }}
          />
          {values.haveSlogan === 'Si' ? (
            <FormInput
              id={`${inputId}-sloganInput`}
              name='slogan'
              type='text'
              onChange={inputHandleChange}
              value={values.slogan}
              formInputProps={{
                label: 'Indique el Slogan *',
                errorMessage: errors.slogan,
                withCheck: false,
                isValidating,
              }}
            />
          ) : null}
          <S.Error>{errors.haveSlogan}</S.Error>
        </S.RadioCont>
        <S.Label>¿Cuentan con algún tipo de logo actualmente?: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-haveLogoSi`,
              label: 'Si',
              name: 'haveLogo',
              radioValue: 'Si',
              value: values.haveLogo,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-haveLogoNo`,
              label: 'No',
              name: 'haveLogo',
              radioValue: 'No',
              value: values.haveLogo,
              handleChange: inputHandleChange,
            }}
          />
          {values.haveLogo === 'Si' ? (
            <FormTextArea
              id={`${inputId}-likeAndDislikeInput`}
              name='likeAndDislike'
              onChange={textAreaHandleChange}
              value={values.likeAndDislike}
              formTextAreaProps={{
                label: 'Que le gusta y disgusta de él *',
                errorMessage: errors.likeAndDislike,
                withCheck: false,
                isValidating,
              }}
            />
          ) : null}
          <S.Error>{errors.haveLogo}</S.Error>
        </S.RadioCont>
        <S.Label>¿Hay algo que deba incluirse en su logotipo?:</S.Label>
        <S.CheckBoxCont>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-willLogoHaveIcon`}
              name='willLogoHaveIcon'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.willLogoHaveIcon}
              formInputProps={{
                label: 'Iconos',

                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-willLogoHaveGraficElmInput`}
              name='willLogoHaveGraficElm'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.willLogoHaveGraficElm}
              formInputProps={{
                label: 'Elementos Gráficos',
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-willLogoHaveWordOrSloganInput`}
              name='willLogoHaveWordOrSlogan'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.willLogoHaveWordOrSlogan}
              formInputProps={{
                label: 'Palabras o Eslogan',
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-willLogoHaveOtherInput`}
              name='willLogoHaveOther'
              type='checkbox'
              placeholder='0230'
              onChange={inputHandleChange}
              checked={values.willLogoHaveOther}
              formInputProps={{
                label: 'Otro',
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          {!!values.willLogoHaveOther ? (
            <FormTextArea
              id={`${inputId}-LogoExtraInput`}
              name='LogoExtra'
              onChange={textAreaHandleChange}
              value={values.LogoExtra}
              formTextAreaProps={{
                label: 'Especifique *',
                errorMessage: errors.LogoExtra,
                withCheck: false,
                isValidating,
              }}
            />
          ) : null}
        </S.CheckBoxCont>

        <FormInput
          id={`${inputId}-colorIndentifyBrandInput`}
          name='colorIndentifyBrand'
          type='text'
          onChange={inputHandleChange}
          value={values.colorIndentifyBrand}
          formInputProps={{
            label: '¿Existe algún color que identifique a la marca actualmente?:',
            errorMessage: errors.colorIndentifyBrand,
            withCheck: false,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-colorToUseInput`}
          name='colorToUse'
          onChange={textAreaHandleChange}
          value={values.colorToUse}
          formTextAreaProps={{
            label: '¿Hay algún color que deba incluirse?:',
            errorMessage: errors.colorToUse,
            withCheck: false,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-colorToNotUseInput`}
          name='colorToNotUse'
          onChange={textAreaHandleChange}
          value={values.colorToNotUse}
          formTextAreaProps={{
            label: '¿Hay algún color que no deba incluirse?:',
            errorMessage: errors.colorToNotUse,
            withCheck: false,
            isValidating,
          }}
        />
        <S.Label>¿Hay alguna preferencia por algún estilo tipográfico?:</S.Label>
        <S.CheckBoxCont>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-isSanSerifATypograficPreferenceInput`}
              name='isSanSerifATypograficPreference'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.isSanSerifATypograficPreference}
              formInputProps={{
                label: 'Sans-serif',
                errorMessage: errors.isSanSerifATypograficPreference,
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-isSerifATypograficPreferenceInput`}
              name='isSerifATypograficPreference'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.isSerifATypograficPreference}
              formInputProps={{
                label: 'Serif',
                errorMessage: errors.isSerifATypograficPreference,
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-isScripATypograficPreferenceInput`}
              name='isScripATypograficPreference'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.isScripATypograficPreference}
              formInputProps={{
                label: 'Script',
                errorMessage: errors.isScripATypograficPreference,
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-isOpenToOtherTypograficInput`}
              name='isOpenToOtherTypografic'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.isOpenToOtherTypografic}
              formInputProps={{
                label: 'Estoy abierto/a a opciones',
                errorMessage: errors.isOpenToOtherTypografic,
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-otherTypograficPreferenceInput`}
              name='otherTypograficPreference'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.otherTypograficPreference}
              formInputProps={{
                label: 'Otro',
                errorMessage: errors.otherTypograficPreference,
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          {!!values.otherTypograficPreference ? (
            <FormTextArea
              id={`${inputId}-typograficExtraInput`}
              name='typograficExtra'
              onChange={textAreaHandleChange}
              value={values.typograficExtra}
              formTextAreaProps={{
                label: 'Especifique *',
                errorMessage: errors.typograficExtra,
                withCheck: false,
                isValidating,
              }}
            />
          ) : null}
        </S.CheckBoxCont>

        <S.Label>¿Hay alguna restricción a considerar al diseñar el nuevo logo?: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-isAnyRestrictionWhenDesignLogoSi`,
              label: 'Si',
              name: 'isAnyRestrictionWhenDesignLogo',
              radioValue: 'Si',
              value: values.isAnyRestrictionWhenDesignLogo,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-isAnyRestrictionWhenDesignLogoNo`,
              label: 'No',
              name: 'isAnyRestrictionWhenDesignLogo',
              radioValue: 'No',
              value: values.isAnyRestrictionWhenDesignLogo,
              handleChange: inputHandleChange,
            }}
          />

          {values.isAnyRestrictionWhenDesignLogo === 'Si' ? (
            <FormTextArea
              id={`${inputId}-logoRestrictionsInput`}
              name='logoRestrictions'
              onChange={textAreaHandleChange}
              value={values.logoRestrictions}
              formTextAreaProps={{
                label: '¿Hay algo en particular que te guste o disguste de otros logos? *',
                errorMessage: errors.logoRestrictions,
                withCheck: false,
                isValidating,
              }}
            />
          ) : null}
          <S.Error>{errors.isAnyRestrictionWhenDesignLogo}</S.Error>
        </S.RadioCont>

        <S.Label>
          Escoge 3 paletas de colores de tu preferencia. Puedes usar el siguiente botón para buscar
          paletas de colores relacionados a tu industria, luego haz un capture de pantalla(o
          descarga la imagen de la paleta de colores), y súbela:
        </S.Label>

        <S.Note>
          NOTA: Las imagenes deben tener un tamaño no mayor de 2mb, y pertenecer a los formatos
          siguientes:
          <br />
          * JPG
          <br />
          * PNG
          <br />* BMP
        </S.Note>
        <S.LinkTo type='button' href='https://color.adobe.com/es/explore' target='_blank'>
          <RegularButton
            style={{
              marginBottom: '30px',
              padding: '8px 15px',
            }}
            regularButtonProps={{ color: 'orange', text: 'Buscar paletas de colores' }}
            type='button'
          />
        </S.LinkTo>

        <S.ImgInputCont>
          <FormInput
            id={`${inputId}-colorPallete1nput`}
            name='colorPallete1'
            type='file'
            accept='image/*'
            onChange={inputFileHandleChange}
            formInputProps={{
              label: 'Paleta de colores 1 *',
              errorMessage: errors.colorPallete1,
              withCheck: false,
              isValidating,
            }}
          />
          {isLoadingImg ? (
            <S.ImgLoaderCont>
              <BeatLoader color='#ffffff' margin={3} speedMultiplier={0.6} />
            </S.ImgLoaderCont>
          ) : null}
        </S.ImgInputCont>
        <S.ImgInputCont>
          <FormInput
            id={`${inputId}-colorPallete2nput`}
            name='colorPallete2'
            type='file'
            accept='image/*'
            onChange={inputFileHandleChange}
            formInputProps={{
              label: 'Paleta de colores 2',
              errorMessage: errors.colorPallete2,
              withCheck: false,
              isValidating,
            }}
          />
          {isLoadingImg ? (
            <S.ImgLoaderCont>
              <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
            </S.ImgLoaderCont>
          ) : null}
        </S.ImgInputCont>
        <S.ImgInputCont>
          <FormInput
            id={`${inputId}-colorPallete3nput`}
            name='colorPallete3'
            type='file'
            accept='image/*'
            onChange={inputFileHandleChange}
            formInputProps={{
              label: 'Paleta de colores 3',
              errorMessage: errors.colorPallete3,
              withCheck: false,
              isValidating,
            }}
          />
          {isLoadingImg ? (
            <S.ImgLoaderCont>
              <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
            </S.ImgLoaderCont>
          ) : null}
        </S.ImgInputCont>

        <S.Label>
          Escoge algunos logos que te gusten y que quisieras utilizar de inspiración. Tómate el
          tiempo que necesites, puedes buscar en Google o Pinterest. Preferiblemente de la misma
          industria, sólo queremos los estilos que te llamen la atención:
        </S.Label>
        <S.Note>
          NOTA: Las imagenes deben tener un tamaño no mayor de 2mb, y pertenecer a los formatos
          siguientes:
          <br />
          * JPG
          <br />
          * PNG
          <br />* BMP
        </S.Note>
        <S.ImgInputCont>
          <FormInput
            id={`${inputId}-logo1nput`}
            name='logo1'
            type='file'
            accept='image/*'
            onChange={inputFileHandleChange}
            formInputProps={{
              label: 'Logo 1 *',
              errorMessage: errors.logo1,
              withCheck: false,
              isValidating,
            }}
          />
          {isLoadingImg ? (
            <S.ImgLoaderCont>
              <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
            </S.ImgLoaderCont>
          ) : null}
        </S.ImgInputCont>
        <S.ImgInputCont>
          <FormInput
            id={`${inputId}-logo2Input`}
            name='logo2'
            type='file'
            accept='image/*'
            onChange={inputFileHandleChange}
            formInputProps={{
              label: 'Logo 2',
              errorMessage: errors.logo2,
              withCheck: false,
              isValidating,
            }}
          />
          {isLoadingImg ? (
            <S.ImgLoaderCont>
              <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
            </S.ImgLoaderCont>
          ) : null}
        </S.ImgInputCont>
        <S.ImgInputCont>
          <FormInput
            id={`${inputId}-logo3Input`}
            name='logo3'
            type='file'
            accept='image/*'
            onChange={inputFileHandleChange}
            formInputProps={{
              label: 'Logo 3',
              errorMessage: errors.logo3,
              withCheck: false,
              isValidating,
            }}
          />
          {isLoadingImg ? (
            <S.ImgLoaderCont>
              <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
            </S.ImgLoaderCont>
          ) : null}
        </S.ImgInputCont>
        <S.ImgInputCont>
          <FormInput
            id={`${inputId}-moodBoardImgInput`}
            name='moodBoardImg'
            type='file'
            accept='image/*'
            onChange={inputFileHandleChange}
            formInputProps={{
              label:
                'Si tienes alguna imagen, concepto, estilo o detalle que quisieras que se refleje en tu logo, puedes incluirlo aquí. (Mood Boards o Panel de Tendencias):',
              errorMessage: errors.moodBoardImg,
              withCheck: false,
              isValidating,
            }}
          />
          {isLoadingImg ? (
            <S.ImgLoaderCont>
              <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
            </S.ImgLoaderCont>
          ) : null}
        </S.ImgInputCont>

        <FormTextArea
          id={`${inputId}-aditionalInfoInput`}
          name='aditionalInfo'
          onChange={textAreaHandleChange}
          value={values.aditionalInfo}
          formTextAreaProps={{
            errorMessage: errors.aditionalInfo,
            label: 'Información adicional sobre la marca o empresa:',
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>

      {isLoading ? (
        <S.SpinnerCont>
          <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
        </S.SpinnerCont>
      ) : (
        <RegularButton
          regularButtonProps={{ color: 'orange', text: 'ENVIAR' }}
          disabled={disableSubmit}
          type='submit'
        />
      )}
    </S.Form>
  );
};

export default BriefForm;
