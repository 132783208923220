import { isValidPhoneNumber } from 'libphonenumber-js';
import { FormErrorsProps, FormValueProps } from '../interfaces/interfaces';
import countries from './countries';

const validation = ({
  //1
  companyName,
  //companyStartDate,
  companyEmail,
  phoneNumber,

  //2
  address1,
  address2,
  city,
  state,
  zipCode,
  country,

  //3
  staffAmount,
  briefCompanyHistory,
  missionVision,
  industrySector,
  productOrService,

  //4
  //Informacion de la marca
  fiverWordsDescribeBrand,
  competitors1,
  //competitors2,
  //competitors3,
  diferenceBetwenYourBrandAndCompetitors,

  //5
  //consumer
  idealConsumerGender,
  actualPrincipalConsumerGender,
  idealConsumerAge,
  idealConsumerSocioEconomicLvl,
  //
  idealConsumerLocationisNacional,
  idealConsumerLocationisAmericaLatina,
  idealConsumerLocationisNorteAmerica,
  idealConsumerLocationisAmericaYEuropa,
  idealConsumerLocationisAmericaYAsia,
  idealConsumerLocationisInternacional,
  //6
  //Informacion Detallada De La Marca
  haveSlogan,
  slogan,
  haveLogo,
  likeAndDislike,
  //
  //willLogoHaveIcon,
  //willLogoHaveGraficElm,
  //willLogoHaveWordOrSlogan,
  //willLogoHaveOther,
  LogoExtra,
  //
  //colorIndentifyBrand,
  //colorToNotUse,
  //colorToUse,
  //isSanSerifATypograficPreference,
  //isSerifATypograficPreference,
  //isScripATypograficPreference,
  //isOpenToOtherTypografic,
  //otherTypograficPreference,
  typograficExtra,
  isAnyRestrictionWhenDesignLogo,
  logoRestrictions,
  colorPallete1,
  //colorPallete2,
  //colorPallete3,
  logo1,
  //logo2,
  //logo3,
  //moodBoardImg,
  aditionalInfo,
}: FormValueProps) => {
  const errors: FormErrorsProps = {
    //1
    companyName: '',
    companyStartDate: '',
    companyEmail: '',
    phoneNumber: '',

    //2
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',

    //3
    staffAmount: '',
    briefCompanyHistory: '',
    missionVision: '',
    industrySector: '',
    productOrService: '',

    //4
    //Informacion de la marca
    fiverWordsDescribeBrand: '',
    competitors1: '',
    competitors2: '',
    competitors3: '',
    diferenceBetwenYourBrandAndCompetitors: '',

    //5
    //consumer
    idealConsumerGender: '',
    actualPrincipalConsumerGender: '',
    idealConsumerAge: '',
    idealConsumerLocation: '',
    idealConsumerSocioEconomicLvl: '',
    //6
    //Informacion Detallada De La Marca
    haveSlogan: '',
    slogan: '',
    haveLogo: '',
    likeAndDislike: '',
    //
    //willLogoHaveIcon: '',
    //willLogoHaveGraficElm: '',
    //willLogoHaveWordOrSlogan: '',
    //willLogoHaveOther: '',
    LogoExtra: '',
    //
    colorIndentifyBrand: '',
    colorToNotUse: '',
    colorToUse: '',
    //
    isSanSerifATypograficPreference: '',
    isSerifATypograficPreference: '',
    isScripATypograficPreference: '',
    isOpenToOtherTypografic: '',
    otherTypograficPreference: '',
    typograficExtra: '',
    //
    isAnyRestrictionWhenDesignLogo: '',
    logoRestrictions: '',
    colorPallete1: '',
    colorPallete2: '',
    colorPallete3: '',
    //
    logo1: '',
    logo2: '',
    logo3: '',
    moodBoardImg: '',

    aditionalInfo: '',
  };

  //Validation for name
  if (!companyName.trim()) {
    errors.companyName = 'Este campo es requerido';
  } else if (companyName.trim() && companyName.trim().length < 3) {
    errors.companyName = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.companyName = '';
  }

  //Validation for email
  if (!companyEmail.trim()) {
    errors.companyEmail = 'Este campo es requerido';
  } else if (
    companyEmail.trim() &&
    !companyEmail
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.companyEmail = 'Use un correo correcto';
  } else {
    errors.companyEmail = '';
  }

  //phoneNumber
  if (!phoneNumber.trim()) {
    errors.phoneNumber = 'Este campo es requerido';
  } else if (phoneNumber.trim() && !isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Use un numero de telefono correcto';
  } else {
    errors.phoneNumber = '';
  }

  //address 1
  if (!address1.trim()) {
    errors.address1 = 'Este campo es requerido';
  } else if (address1.trim() && address1.trim().length < 3) {
    errors.address1 = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.address1 = '';
  }

  //address 2
  if (address2.trim() && address2.trim().length < 3) {
    errors.address2 = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.address2 = '';
  }

  //city
  if (city.trim() && city.trim().length < 3) {
    errors.city = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.city = '';
  }

  //state
  if (state.trim() && state.trim().length < 3) {
    errors.state = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.state = '';
  }

  //zipcode
  if (zipCode.trim() && !/^[0-9\- ]*$/.test(zipCode.trim())) {
    errors.zipCode = 'Solo numero y guiones son permitidos';
  } else {
    errors.zipCode = '';
  }

  //country
  if (!country.trim()) {
    errors.country = 'Este campo es requerido';
  } else if (
    !!!countries.find(
      (item) => item.name === country.replace(/\b[a-z]/g, (char) => char.toUpperCase())
    )
  ) {
    errors.country = 'Elija un nombre de pais valido';
  } else {
    errors.country = '';
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = 'Este campo es requerido';
  } else {
    errors.staffAmount = '';
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = 'Este campo es requerido';
  } else {
    errors.staffAmount = '';
  }

  //company history
  if (!briefCompanyHistory.trim()) {
    errors.briefCompanyHistory = 'Este campo es requerido';
  } else {
    errors.briefCompanyHistory = '';
  }

  //mission vision
  if (!missionVision.trim()) {
    errors.missionVision = 'Este campo es requerido';
  } else {
    errors.missionVision = '';
  }

  //industry sector
  if (!industrySector.trim()) {
    errors.industrySector = 'Este campo es requerido';
  } else {
    errors.industrySector = '';
  }

  //product or service
  if (!productOrService.trim()) {
    errors.productOrService = 'Este campo es requerido';
  } else {
    errors.productOrService = '';
  }

  //fiverWordsDescribeBrand
  if (!fiverWordsDescribeBrand.trim()) {
    errors.fiverWordsDescribeBrand = 'Este campo es requerido';
  } else {
    errors.fiverWordsDescribeBrand = '';
  }

  //competitors1
  if (!competitors1.trim()) {
    errors.competitors1 = 'Este campo es requerido';
  } else {
    errors.competitors1 = '';
  }

  //diferenceBetwenYourBrandAndCompetitors
  if (!diferenceBetwenYourBrandAndCompetitors.trim()) {
    errors.diferenceBetwenYourBrandAndCompetitors = 'Este campo es requerido';
  } else {
    errors.diferenceBetwenYourBrandAndCompetitors = '';
  }

  if (!idealConsumerGender.trim()) {
    errors.idealConsumerGender = 'Este campo es requerido';
  } else {
    errors.idealConsumerGender = '';
  }

  if (!actualPrincipalConsumerGender.trim()) {
    errors.actualPrincipalConsumerGender = 'Este campo es requerido';
  } else {
    errors.actualPrincipalConsumerGender = '';
  }

  if (!idealConsumerAge.trim()) {
    errors.idealConsumerAge = 'Este campo es requerido';
  } else {
    errors.idealConsumerAge = '';
  }

  const idealConsumerLocation = {
    idealConsumerLocationisNacional,
    idealConsumerLocationisAmericaLatina,
    idealConsumerLocationisNorteAmerica,
    idealConsumerLocationisAmericaYEuropa,
    idealConsumerLocationisAmericaYAsia,
    idealConsumerLocationisInternacional,
  };

  const noOneIsPick = Object.values(idealConsumerLocation).every((location) => location === false);

  if (noOneIsPick) {
    errors.idealConsumerLocation = 'Este campo es requerido';
  } else {
    errors.idealConsumerLocation = '';
  }

  if (!idealConsumerSocioEconomicLvl.trim()) {
    errors.idealConsumerSocioEconomicLvl = 'Este campo es requerido';
  } else {
    errors.idealConsumerSocioEconomicLvl = '';
  }

  if (!haveSlogan.trim()) {
    errors.haveSlogan = 'Este campo es requerido';
  } else {
    errors.haveSlogan = '';
  }

  if (slogan.trim() && slogan.trim().length < 3) {
    errors.slogan = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.slogan = '';
  }

  if (!haveLogo.trim()) {
    errors.haveLogo = 'Este campo es requerido';
  } else {
    errors.haveLogo = '';
  }

  if (likeAndDislike.trim() && likeAndDislike.trim().length < 6) {
    errors.likeAndDislike = 'Minimo debe escribir 6 caracteres';
  } else {
    errors.likeAndDislike = '';
  }

  if (LogoExtra.trim() && LogoExtra.trim().length < 6) {
    errors.LogoExtra = 'Minimo debe escribir 6 caracteres';
  } else {
    errors.LogoExtra = '';
  }

  if (typograficExtra.trim() && typograficExtra.trim().length < 6) {
    errors.typograficExtra = 'Minimo debe escribir 6 caracteres';
  } else {
    errors.typograficExtra = '';
  }

  if (!isAnyRestrictionWhenDesignLogo.trim()) {
    errors.isAnyRestrictionWhenDesignLogo = 'Este campo es requerido';
  } else {
    errors.isAnyRestrictionWhenDesignLogo = '';
  }

  if (logoRestrictions.trim() && logoRestrictions.trim().length < 6) {
    errors.logoRestrictions = 'Minimo debe escribir 6 caracteres';
  } else {
    errors.logoRestrictions = '';
  }

  if (!colorPallete1) {
    errors.colorPallete1 = 'Este campo es requerido';
  } else {
    errors.colorPallete1 = '';
  }

  if (!logo1.trim()) {
    errors.logo1 = 'Este campo es requerido';
  } else {
    errors.logo1 = '';
  }

  if (aditionalInfo.trim() && aditionalInfo.trim().length < 6) {
    errors.aditionalInfo = 'Minimo debe escribir 6 caracteres';
  } else {
    errors.aditionalInfo = '';
  }

  return errors;
};

export default validation;
