import { FormValueProps, FormErrorsProps } from '../interfaces';

export const formValues: FormValueProps = {
  //1
  companyName: '',
  companyStartDate: '',
  companyEmail: '',
  phoneNumber: '',

  //2
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',

  //3
  staffAmount: '',
  briefCompanyHistory: '',
  missionVision: '',
  industrySector: '',
  productOrService: '',

  //4
  //Informacion de la marca
  fiverWordsDescribeBrand: '',
  competitors1: '',
  competitors2: '',
  competitors3: '',
  diferenceBetwenYourBrandAndCompetitors: '',

  //5
  //consumer
  idealConsumerGender: '',
  actualPrincipalConsumerGender: '',
  idealConsumerAge: '',
  idealConsumerSocioEconomicLvl: '',
  //
  idealConsumerLocationisNacional: false,
  idealConsumerLocationisAmericaLatina: false,
  idealConsumerLocationisNorteAmerica: false,
  idealConsumerLocationisAmericaYEuropa: false,
  idealConsumerLocationisAmericaYAsia: false,
  idealConsumerLocationisInternacional: false,
  //6
  //Informacion Detallada De La Marca
  haveSlogan: '',
  slogan: '',
  haveLogo: '',
  likeAndDislike: '',
  //
  willLogoHaveIcon: false,
  willLogoHaveGraficElm: false,
  willLogoHaveWordOrSlogan: false,
  willLogoHaveOther: false,
  LogoExtra: '',
  //
  colorIndentifyBrand: '',
  colorToNotUse: '',
  colorToUse: '',
  //
  isSanSerifATypograficPreference: false,
  isSerifATypograficPreference: false,
  isScripATypograficPreference: false,
  isOpenToOtherTypografic: false,
  otherTypograficPreference: false,
  typograficExtra: '',
  //
  isAnyRestrictionWhenDesignLogo: '',
  logoRestrictions: '',
  colorPallete1: '',
  colorPallete2: '',
  colorPallete3: '',
  logo1: '',
  logo2: '',
  logo3: '',
  moodBoardImg: '',

  aditionalInfo: '',
};

export const formErrors: FormErrorsProps = {
  //1
  companyName: '',
  companyStartDate: '',
  companyEmail: '',
  phoneNumber: '',

  //2
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',

  //3
  staffAmount: '',
  briefCompanyHistory: '',
  missionVision: '',
  industrySector: '',
  productOrService: '',

  //4
  //Informacion de la marca
  fiverWordsDescribeBrand: '',
  competitors1: '',
  competitors2: '',
  competitors3: '',
  diferenceBetwenYourBrandAndCompetitors: '',

  //5
  //consumer
  idealConsumerGender: '',
  actualPrincipalConsumerGender: '',
  idealConsumerAge: '',
  idealConsumerLocation: '',
  idealConsumerSocioEconomicLvl: '',
  //6
  //Informacion Detallada De La Marca
  haveSlogan: '',
  slogan: '',
  haveLogo: '',
  likeAndDislike: '',
  //
  //willLogoHaveIcon: '',
  //willLogoHaveGraficElm: '',
  //willLogoHaveWordOrSlogan: '',
  //willLogoHaveOther: '',
  LogoExtra: '',
  //
  colorIndentifyBrand: '',
  colorToNotUse: '',
  colorToUse: '',
  //
  isSanSerifATypograficPreference: '',
  isSerifATypograficPreference: '',
  isScripATypograficPreference: '',
  isOpenToOtherTypografic: '',
  otherTypograficPreference: '',
  typograficExtra: '',
  //
  isAnyRestrictionWhenDesignLogo: '',
  logoRestrictions: '',
  colorPallete1: '',
  colorPallete2: '',
  colorPallete3: '',
  logo1: '',
  logo2: '',
  logo3: '',
  moodBoardImg: '',

  aditionalInfo: '',
};
